<template>

  <div class="create-user">
    
    <h1> Criar uma nova conta </h1>
    
    <br>

    <div v-if="this.user" class="form">

      <div class="photo-el-input">

        <h3> Foto de perfil </h3>

        <div class="create-el-input">

          <el-upload v-model:file-list="fileList" accept="image/png" class="upload-demo" :on-change="uploadImage" :auto-upload="false" :limit="1" list-type="picture">
            <el-avatar :size="100" :src="getUserPhoto" class="avatar"/>
            <template #file>
              <i class="el-icon el-icon--close" @click="removeImage()">
                <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                  <path fill="currentColor" d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z">
                  </path>
                </svg>
              </i>
              {{ fileName }}
            </template>
          </el-upload>

        </div>

      </div>

      <el-divider direction="vertical" class="vertical-divisor"></el-divider>

      <div class="common-el-input">

        <h3> Usuário </h3>

        <div class="create-el-input">
          <span> Username </span>
          <el-input type="text" @change="initFieldEdit('username')" minlength="1" maxlength="10" v-model="user.username" class="name-el-input" :class="invalidUsernameFieldValue()"/>
          <i> Máximo 10 caracteres </i>
        </div>

        <div class="create-el-input">
          <span> Senha </span>
          <el-input type="password" @change="initFieldEdit('password')" minlength="6" maxlength="12" v-model="user.password" class="name-el-input" :class="invalidPasswordFieldValue()" clearable show-password/>
          <i> Possui ao menos uma letra em maiúsculo </i>
          <i> Possui ao menos um caracter especial </i>
          <i> Possui ao menos uma letra </i>
          <i> Possui ao menos um número </i>
          <i> Possui no minimo 6 caracteres </i>
          <i> Possui no máximo 12 caracteres </i>
        </div>

        <div class="create-el-input">
          <span> Confirmar senha </span>
          <el-input type="password" @change="initFieldEdit('passwordConfirmation')" minlength="6" maxlength="12" v-model="passwordConfirmation" class="name-el-input" :class="invalidPasswordConfirmationFieldValue()" clearable show-password/>
        </div>

        <el-divider direction="horizontal"></el-divider>

        <h3> Informações pessoais </h3>

        <div class="create-el-input">
          <span> Primeiro nome </span>
          <el-input type="text" @change="initFieldEdit('firstName')" minlength="1" maxlength="255" placeholder="First Name" v-model="user.firstName" :class="invalidFirstNameFieldValue()" class="name-el-input"/>
          <i> Começa com letra maiúscula </i>
        </div>

        <div class="create-el-input">
          <span> Sobrenome </span>
          <el-input type="text" @change="initFieldEdit('lastName')" minlength="1" maxlength="255" placeholder="Last Name" v-model="user.lastName" :class="invalidLastNameFieldValue()" class="name-el-input"/>
          <i> Começa com letra maiúscula </i>
        </div>

        <div class="create-el-input">
          <span> CPF: </span>
          <el-input type="text" @change="initFieldEdit('socialId')" minlength="1" maxlength="14" v-maska data-maska="###.###.###-##" placeholder="123.456.789-10" v-model="user.socialId" :class="invalidSocialIdFieldValue()" class="name-el-input"/>
        </div>

        <div class="create-el-input">
          <span> E-mail </span>
          <el-input type="text" @change="initFieldEdit('email')" minlength="1" maxlength="255" v-model="user.email" :class="invalidEmailFieldValue()" placeholder="foo@foo.com" class="name-el-input"/>
        </div>

        <div class="create-el-input">
          <span> Celular </span>
          <el-input type="text" @change="initFieldEdit('phoneNumber')" minlength="1" maxlength="19" v-maska data-maska="+## (##) #####-####" v-model="user.phoneNumber" :class="invalidPhoneNumberFieldValue()" placeholder="+55 (31) 91234-5678" class="name-el-input"/>
        </div>

      </div>

      <el-divider direction="vertical" class="vertical-divisor"></el-divider>

      <el-divider direction="horizontal" class="horizontal-divisor"></el-divider>

      <div class="company-config-input">

        <div class="company-config-table">

          <h3>
            Limites
            <el-tooltip class="box-item" effect="dark" content="Limites planejados por categoria" placement="top">
                <el-icon class="info-icon"><InfoFilled/></el-icon>
            </el-tooltip>
          </h3>
          <table v-loading="configTableLoading" :element-loading-svg="loadingSvg" element-loading-text="Carregando..." element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="transparent" class="config-table">

              <thead>
                  <tr>
                      <th> Categoria </th>
                      <th> Limite mensal </th>
                  </tr>
              </thead>
              <tbody v-if="userCompanyConfigList.length > 0">
                  <tr v-for="companyConfig in userCompanyConfigList" :key="companyConfig">
                      <td>
                        <el-tag :key="companyConfig.type" class="mx-1 table-tag" :disable-transitions="false">
                          <span :class="companyConfig.tagTypeClassName"> {{ getType(companyConfig.type) }} </span>
                        </el-tag>
                      </td>
                      <td>
                          <currency-input v-model="companyConfig.monthLimit" :options="getPtCurrency()" class="price-input"/>
                      </td>
                  </tr>
                  <tr class="total-row">
                    <td> TOTAL </td>
                    <td> {{ getFormattedValue(userCompanyConfigTotal, "pt_br") }}  </td>
                  </tr>
              </tbody>

          </table>

        </div>

      </div>

    </div>

    <div class="foot-buttons">
      <el-button type="success" :disabled="!validRequest(user)" @click="createUser()"> Cadastrar </el-button>
      <el-tooltip class="box-item" effect="dark" content="Voltar para a página inicial" placement="top">
        <el-button type="primary" @click="goToHome()" class="back-button"> Voltar </el-button>
      </el-tooltip>
    </div>

  </div>

</template>

<script>

  import { createUser } from '@/http/user/anonymous-user-service';
  import { convertBase64ToImage } from '@/util/AppUtils';
  import { DEFAULT_AVATAR_IMAGE_URL, DEFAULT_LOADING_SVG } from '@/constants/AppConstants';
  import { HOME_PATH } from '@/constants/RoutesConstants';
  import { ElMessage } from 'element-plus'
  import { getFormattedValue } from '@/util/MoneyUtils';
  import CurrencyInput from "@/component/common/CurrencyInput";
  import CompanyType from '@/constants/CompanyType';

  export default {
    name: 'create-user-view',
    components: { CurrencyInput },
    data() {
      return {
        user: {
          id: null,
          image: null,
          username: "",
          password: "",
          firstName: "",
          lastName: "",
          socialId: "",
          email: "",
          phoneNumber: "",
          companyConfig: []
        },
        configTableLoading: false,
        fileName: null,
        fileList: [],
        loadingSvg: DEFAULT_LOADING_SVG,
        passwordConfirmation: "",
        getFormattedValue,
        fields: {
          username: false,
          password: false,
          passwordConfirmation: false,
          firstName: false,
          lastName: false,
          socialId: false,
          email: false,
          phoneNumber: false
        }
      }
    },
    methods: {
      initFieldEdit(field) {
        this.fields[field] = true;
      },
      invalidUsernameFieldValue() {

        if(!this.fields.username) {
          return "";
        }

        return this.user.username === null || this.user.username === undefined || this.user.username === "" || this.user.username.length <= 0 || this.user.username.length > 10 ?
        "invalid-field" :
        "valid-field";

      },
      invalidPasswordFieldValue() {

        if(!this.fields.password) {
          return "";
        }

        return this.user.password === null || 
        this.user.password === undefined || 
        this.user.password.length === "" || 
        this.user.password.length <= 6 || 
        this.user.password.length > 12 ||
        !/[A-Z]/.test(this.user.password) ||
        !/[1-9]/.test(this.user.password) ||
        !/[!@#$%&]/.test(this.user.password) ?
        "invalid-field" :
        "valid-field";

      },
      invalidPasswordConfirmationFieldValue() {

        if(!this.fields.passwordConfirmation) {
          return "";
        }

        return this.passwordConfirmation === null || 
        this.passwordConfirmation === undefined || 
        this.passwordConfirmation.length === "" || 
        this.passwordConfirmation.length <= 6 || 
        this.passwordConfirmation.length > 12 ||
        !/[A-Z]/.test(this.passwordConfirmation) ||
        !/[1-9]/.test(this.passwordConfirmation) ||
        !/[!@#$%&]/.test(this.passwordConfirmation) ?
        "invalid-field" :
        "valid-field";

      },
      invalidFirstNameFieldValue() {

        if(!this.fields.firstName) {
          return "";
        }

        return this.user.firstName === null ||
        this.user.firstName === undefined ||
        this.user.firstName.length === "" ||
        this.user.firstName.length <= 1 || 
        !/^[A-Z]/.test(this.user.firstName) ?
        "invalid-field" :
        "valid-field";

      },
      invalidLastNameFieldValue() {

        if(!this.fields.lastName) {
          return "";
        }

        return this.user.lastName === null ||
        this.user.lastName === undefined ||
        this.user.lastName.length === "" ||
        this.user.lastName.length <= 1 ||
        !/^[A-Z]/.test(this.user.lastName) ?
        "invalid-field" :
        "valid-field";

      },
      invalidSocialIdFieldValue() {

        if(!this.fields.socialId) {
          return "";
        }

        return this.user.socialId === null || this.user.socialId === undefined || this.user.socialId.length === "" || this.user.socialId.length !== 14 ?
        "invalid-field" :
        "valid-field";

      },
      invalidEmailFieldValue() {

        if(!this.fields.email) {
          return "";
        }

        const emailSpecialCharIndex = this.user.email.indexOf("@");
        const emailDotCharIndex = this.user.email.indexOf(".");

        return this.user.email === null ||
        this.user.email === undefined ||
        this.user.email === "" ||
        this.user.email.length <= 0 || 
        emailSpecialCharIndex <= 0 || 
        emailSpecialCharIndex >= emailDotCharIndex - 1 || 
        emailDotCharIndex >= this.user.email.length - 1 ?
        "invalid-field" :
        "valid-field";

      },
      invalidPhoneNumberFieldValue() {

        if(!this.fields.phoneNumber) {
          return "";
        }

        return this.user.phoneNumber === null || this.user.phoneNumber === undefined || this.user.phoneNumber.length < 19 ?
        "invalid-field" :
        "valid-field";

      },
      createUser() {

        if(this.validRequest(this.user)) {

          if(this.user.password === this.passwordConfirmation) {

            const socialId = this.user.socialId.replaceAll(".", "").replaceAll("-", "");
            const userRegister = {
              id: null,
              username: this.user.username,
              password: btoa(this.user.password),
              firstName: this.user.firstName,
              lastName: this.user.lastName,
              socialId: socialId,
              email: this.user.email,
              companyConfig: this.user.companyConfig,
              image: this.user.image
            }
            createUser(userRegister).then(() => {

              ElMessage({
                message: 'Usuário criado com sucesso!',
                type: 'success'
              });
              this.$router.push({ path: HOME_PATH })

            }).catch(() => {
              ElMessage({
                message: 'Usuário não pode ser criado, tente novamente mais tarde!',
                type: 'error'
              });
            });

          } else {
            ElMessage({
              message: 'Senhas incompatíveis!',
              type: 'error'
            });
          }          

        }

      },
      uploadImage(e){

        const image = e.raw;
        this.fileName = e.name;
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = e =>{
            const previewImage = e.target.result;
            const base64 = previewImage.split(',');
            this.user.image = {
              base64: base64[1]
            };
            this.user.photoBase64 = base64[1];
        };
        
      },
      removeImage() {
        this.user.image = null;
        this.user.photoBase64 = null;
        this.fileName = "";
        this.fileList = [];
      },
      validRequest(user) {

        if(user.username === null || user.username === undefined || user.username === "" || user.username.length <= 0 || user.username.length > 10) {
          return false;
        }

        if(user.password === null || 
          user.password === undefined || 
          user.password.length === "" || 
          user.password.length <= 6 || 
          user.password.length > 12 ||
          !/[A-Z]/.test(user.password) ||
          !/[1-9]/.test(user.password) ||
          !/[!@#$%&]/.test(user.password)) {
          return false;
        }

        if(this.passwordConfirmation === null || 
          this.passwordConfirmation === undefined || 
          this.passwordConfirmation.length === "" || 
          this.passwordConfirmation.length <= 6 || 
          this.passwordConfirmation.length > 12 ||
          !/[A-Z]/.test(this.passwordConfirmation) ||
          !/[1-9]/.test(this.passwordConfirmation) ||
          !/[!@#$%&]/.test(this.passwordConfirmation)) {
          return false;
        }

        if(user.firstName === null ||
          user.firstName === undefined ||
          user.firstName.length === "" ||
          user.firstName.length <= 1 || 
          !/^[A-Z]/.test(user.firstName)) {
          return false;
        }

        if(user.lastName === null ||
          user.lastName === undefined ||
          user.lastName.length === "" ||
          user.lastName.length <= 1 ||
          !/^[A-Z]/.test(user.lastName)) {
          return false;
        }

        if(user.socialId === null || user.socialId === undefined || user.socialId.length === "" || user.socialId.length !== 14) {
          return false;
        }

        const emailSpecialCharIndex = user.email.indexOf("@");
        const emailDotCharIndex = user.email.indexOf(".");
        if(user.email === null ||
          user.email === undefined ||
          user.email === "" ||
          user.email.length <= 0 || 
          emailSpecialCharIndex <= 0 || 
          emailSpecialCharIndex >= emailDotCharIndex - 1 || 
          emailDotCharIndex >= user.email.length - 1) {
          return false;
        }
        
        if(user.phoneNumber === null || user.phoneNumber === undefined || user.phoneNumber.length < 19) {
          return false;
        }

        return true;

      },
      getPtCurrency() {
        return { currency: "BRL" };
      },
      getType(type) {
        return CompanyType[type].description;
      },
      goToHome() {
        this.$router.push({ path: HOME_PATH });
      }
    },
    computed: {
      getUserPhoto() {

        if(this.user.photoBase64 !== undefined && this.user.photoBase64 !== null && this.user.photoBase64 !== '') {
            return convertBase64ToImage(this.user.photoBase64);
        }

        return DEFAULT_AVATAR_IMAGE_URL;

      },
      userCompanyConfigList() {
        Object.keys(CompanyType).forEach(ct => {
          this.user.companyConfig.push({
            tagTypeClassName: CompanyType[ct].tagTypeClassName,
            monthLimit: 0,
            type: ct
          })
        });
        return this.user.companyConfig;
      },
      userCompanyConfigTotal() {
        return this.user.companyConfig.map(config => config.monthLimit).reduce((partialSum, a) => partialSum + a, 0); 
      }
    }
  }

</script>

<style lang="scss" scoped>

  @media screen and (max-width: 1024px) {

    .form {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 50px;
    }

    .vertical-divisor {
      display: none;
    }

    .company-config-input {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }


    .table-tag {
      width: 80%;
    }

  }

  @media screen and (min-width: 1024px) and (max-width: 1920px) {

    .form {
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 50px;
    }

    .horizontal-divisor {
      display: none;
    }

    .company-config-input {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-left: 50px;
    }

    .table-tag {
      width: 35%;
    }

  }

  .create-user {
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }

  .create-el-input {
    float: left;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
    flex-direction: column;
    font-weight: bold;
  }

  .name-el-input {
    font-weight: bold;
    margin-top: 5px;
  }

  .common-el-input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .photo-el-input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
  }

  .avatar {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .avatar:hover {
    cursor: pointer;
    border: 1px solid gray;
  }

  .foot-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 97%;
  }

  .company-config-table {
    width: 100%;
  }

  .el-divider--vertical {
    height: 50em;
    border-left: 1px $default-secondary-color var(--el-border-style);
  }

  .el-divider--horizontal {
    border-top: 1px $default-secondary-color var(--el-border-style);
  }

  i {
    font-size: 12px;
    color: grey;
    margin-top: 5px;
  }

</style>
../../http/user/anonymous-user-service