<template>

    <div class="capital-summary-section">

        <h3>
            HISTÓRICO
            <el-tooltip class="box-item" effect="dark" content="Histórico da carteira" placement="top">
                <el-icon class="info-icon"><InfoFilled/></el-icon>
            </el-tooltip>
        </h3>
        <div class="year-picker">
            <el-date-picker v-model="selectedYear" @change="changeData()" :default-value="new Date()" :disabled-date="disabledDate" :clearable="false" size="small" type="year" placeholder="Selecione o ano"/>
        </div>
        <div v-loading="barLoading" :element-loading-svg="loadingSvg" element-loading-text="Carregando..." element-loading-svg-view-box="-10, -10, 50, 50" class="line">
            <Line v-if="showLine" :data="getData" :options="options"/>
        </div>
        <div class="table-info">
            <table-information :bgcolor="'green'" :bcolor="'green'" :text="'Aumentou'"/>
            <table-information :bgcolor="'#ff484e'" :bcolor="'#ff484e'" :text="'Diminuiu'"/>
        </div>

    </div>

</template>

<script>

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js'
import { Line } from 'vue-chartjs'
import { DEFAULT_LOADING_SVG } from '@/constants/AppConstants';
import TableInformation from '@/component/common/TableInformation';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export default {
    name: "capital-summary-section",
    components: { Line, TableInformation },
    props: {
        tableLoading: Boolean,
        capitalSummary: Object
    },
    data() {
        return {
            loadingSvg: DEFAULT_LOADING_SVG,
            barLoading: false,
            selectedYear: new Date(),
            data: {
                labels: [ "Janeiro/" + new Date().getFullYear(),"Fevereiro/" + new Date().getFullYear(), "Março/" + new Date().getFullYear(), "Abril/" + new Date().getFullYear(), "Maio/" + new Date().getFullYear(), "Junho/" + new Date().getFullYear(), "Julho/" + new Date().getFullYear(), "Agosto/" + new Date().getFullYear(), "Setembro/" + new Date().getFullYear(), "Outubro/" + new Date().getFullYear(), "Novembro/" + new Date().getFullYear(), "Dezembro/" + new Date().getFullYear() ],
                datasets: [
                    {
                        label: "Patrimônio",
                        color: "#f2e33a",
                        borderColor: "#f2e33a",
                        backgroundColor: [ '#f2e33a', '#f2e33a', '#f2e33a', '#f2e33a', '#f2e33a', '#f2e33a', '#f2e33a', '#f2e33a', '#f2e33a', '#f2e33a', '#f2e33a', '#f2e33a' ],
                        data: [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ]
                    },
                    {
                        label: "Investido",
                        color: "#409eff",
                        borderColor: "#409eff",
                        backgroundColor: [ '#409eff', '#409eff', '#409eff', '#409eff', '#409eff', '#409eff', '#409eff', '#409eff', '#409eff', '#409eff', '#409eff', '#409eff' ],
                        data: [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ]
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                elements:{
                    point:{
                        pointRadius: 5
                    }
                },
                interaction: {
                    intersect: false
                },
                scales: {
                    y: {
                        ticks: {
                            stepSize: 1000
                        },
                        beginAtZero: false
                    }
                }
            },
            freshData: false
        }
    },
    created() {
        this.changeData();
    },
    methods: {
        disabledDate(date) {

            if(this.capitalSummary.capitalMonthCapitalType.length > 0) {

                const lastBalanceIndex = this.capitalSummary.capitalMonthCapitalType.length - 1;

                const firstBalance = this.capitalSummary.capitalMonthCapitalType[0];
                const firstBalanceDate = new Date(firstBalance.month);

                const lastBalance = this.capitalSummary.capitalMonthCapitalType[lastBalanceIndex];
                const lastBalanceDate = new Date(lastBalance.month);
                
                return date.getFullYear() < firstBalanceDate.getFullYear() || date.getFullYear() > lastBalanceDate.getFullYear();

            }
            
            return true;

        },
        changeData() {

            let patrimonyDataset = this.data.datasets[0];
            patrimonyDataset.data = [];
            let capitalDataset = this.data.datasets[1];
            capitalDataset.data = [];
            this.data = {
                labels: this.data.labels,
                datasets: [
                    patrimonyDataset,
                    capitalDataset
                ]
            }
            this.setPatrimonyData();
            this.setCapitalData();
            
            this.freshData = true;

        },
        setCapitalData() {

            let monthIndex = 0;
            this.capitalSummary.capitalMonthCapitalType
            .filter(monthType => {
                const monthDate = new Date(monthType.month);
                return this.selectedYear.getUTCFullYear() == monthDate.getUTCFullYear();
            })
            .forEach(month => {

                this.data.datasets[1].data.push(month.total);
                this.data.datasets[1].backgroundColor[monthIndex] = "#409eff";
                this.data.datasets[1].borderColor = "#409eff";
                this.data.labels[monthIndex] = this.data.labels[monthIndex].substring(0, this.data.labels[monthIndex].length - 4) + this.selectedYear.getFullYear();

                if(monthIndex > 0 && this.data.datasets[1].data[monthIndex] > 0) {
                    
                    if(this.data.datasets[1].data[monthIndex - 1] < this.data.datasets[1].data[monthIndex]) {
                        this.data.datasets[1].backgroundColor[monthIndex] = "#03a94e";
                    } else if(this.data.datasets[1].data[monthIndex - 1] > this.data.datasets[1].data[monthIndex]) {
                        this.data.datasets[1].backgroundColor[monthIndex] = "#fb2a36";
                    }

                }
                monthIndex++;

            });

        },
        setPatrimonyData() {

            let monthIndex = 0;
            this.capitalSummary.patrimonyMonthCapitalType
            .filter(monthType => {
                const monthDate = new Date(monthType.month);
                return this.selectedYear.getUTCFullYear() == monthDate.getUTCFullYear();
            })
            .forEach(month => {

                if(month.total <= 0) {
                    month.total = null;
                }
                this.data.datasets[0].data.push(month.total);
                this.data.datasets[0].backgroundColor[monthIndex] = "#f2e33a";
                this.data.datasets[0].borderColor = "#f2e33a";
                this.data.labels[monthIndex] = this.data.labels[monthIndex].substring(0, this.data.labels[monthIndex].length - 4) + this.selectedYear.getFullYear();

                if(monthIndex > 0 && this.data.datasets[0].data[monthIndex] > 0) {
                    
                    if(this.data.datasets[0].data[monthIndex - 1] < this.data.datasets[0].data[monthIndex]) {
                        this.data.datasets[0].backgroundColor[monthIndex] = "#03a94e";
                    } else if(this.data.datasets[0].data[monthIndex - 1] > this.data.datasets[0].data[monthIndex]) {
                        this.data.datasets[0].backgroundColor[monthIndex] = "#fb2a36";
                    }

                }
                monthIndex++;

            });

        }
    },
    computed: {
        showLine() {
            return this.freshData;
        },
        getData() {
            return this.data;
        }
    }
}

</script>

<style lang="scss" scoped>
    
    .capital-summary-section {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .el-input__prefix-inner {
        color: black !important;
    }

    @media screen and (max-width: 1024px) {

        .year-picker {
            width: 21%;
            float: left;
            text-align: left;
        }

        .line {
            height: 300px !important;
        }

    }

    @media screen and (min-width: 1024px) and (max-width: 1920px) {

        .year-picker {
            width: 10%;
            float: left;
            text-align: left;
        }

        .line {
            height: 250px;
        }

    }

</style>
