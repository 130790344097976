<template>

    <confirm-dialog :visible="isCreateCompanyAlertDialogVisible"
        headerText="Criar novo alerta"
        confirmText="Confirmar"
        cancelText="Cancelar"
        :isConfirmButtonDisabled="isCreateAlertConfirmButtonDisabled"
        @confirm="createCompanyAlert()"
        @cancel="closeCreateCompanyAlertDialog()">
        <template #body>
          <create-company-alert-dialog :setCompanyAlert="setCompanyAlert" :currencyInputOptions="getCompanyCurrency"/>
        </template>
    </confirm-dialog>

    <confirm-dialog :visible="isUpdateCompanyAlertDialogVisible"
        headerText="Editar alerta"
        confirmText="Confirmar"
        cancelText="Cancelar"
        :isConfirmButtonDisabled="isUpdateAlertConfirmButtonDisabled"
        @confirm="updateCompanyAlert()"
        @cancel="closeUpdateCompanyAlertDialog()">
        <template #body>
          <update-company-alert-dialog :companyAlert="companyAlertToUpdate" :currencyInputOptions="getCompanyCurrency"/>
        </template>
    </confirm-dialog>

    <confirm-dialog :visible="isDeleteCompanyAlertDialogVisible"
        headerText="Remover o alerta"
        confirmText="Confirmar"
        cancelText="Cancelar"
        @confirm="deleteCompanyAlert()"
        @cancel="closeDeleteCompanyAlertDialog()">
    </confirm-dialog>

    <div class="company-alert-section">

        <h3>
            CONFIGURAÇÃO
            <el-tooltip class="box-item" effect="dark" content="Alertas configurados" placement="top">
                <el-icon class="info-icon"><InfoFilled/></el-icon>
            </el-tooltip>
        </h3>
        <div class="table-buttons">
            <el-button type="success" @click="showCreateCompanyAlertDialog()" :disabled="alerts.length >= 10"> Criar </el-button>
        </div>
        <div class="alerts-table">

            <table v-loading="tableLoading" :element-loading-svg="loadingSvg" element-loading-text="Carregando..." element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="transparent">

                <thead>
                    <tr>
                        <th> Categoria </th>
                        <th> Ativo </th>
                        <th> Tipo de alerta </th>
                        <th> Preço </th>
                        <th> Preço atual </th>
                        <th> Último alerta enviado </th>
                        <th> Notificações </th>
                        <th> Ações </th>
                    </tr>
                </thead>
                <tbody v-if="alerts.length > 0">

                    <tr v-for="alert in alerts" :key="alert">

                        <td>
                            <span :class="alert.typeClassName"> {{ getCompanyType(alert.company.type) }} </span>
                        </td>

                        <td>
                            <div class="image-name" @click="goToCompanyDetails(alert.company.name)">
                                <img v-if="alert.company.imageUrl != null" style="margin-right: 10px" :src="alert.company.imageUrl" width="30" height="20">
                                <span :class="alert.tagTypeClassName"> {{ alert.company.name }} </span>
                            </div>
                        </td>
                        
                        <td>
                            <span> {{ getType(alert.type) }} </span>
                        </td>
                        
                        <td>
                            <span v-check-sensitive-data :class="getValueClassName(alert)"> {{ getFormattedAlertPrice(alert) }} </span>
                        </td>

                        <td>
                            <span v-check-sensitive-data class="current-price"> {{ getFormattedCurrentPrice(alert) }} </span>
                        </td>

                        <td>
                            <span> {{ getLastAlertSentDate(alert.lastTimeSent) }} </span>
                        </td>
                        
                        <td>
                            <span> {{ getEnabledAlerts(alert) }} </span>
                        </td>

                        <td>
                            <div class="actions">
                                <el-tooltip class="box-item" effect="dark" content="Editar alerta" placement="top">
                                    <el-button type="info" icon="Edit" circle @click="showUpdateCompanyAlertDialog(alert)" class="action-button"/>
                                </el-tooltip>
                                <el-tooltip class="box-item" effect="dark" content="Remover alerta" placement="top">
                                    <el-button type="danger" icon="Delete" circle @click="showDeleteCompanyAlertDialog(alert.id)" class="action-button"/>
                                </el-tooltip>
                            </div>
                        </td>
                        
                    </tr>
                 
                </tbody>

            </table>

            <div v-if="alerts.length === 0">
                <EmptyResult></EmptyResult>
            </div>

        </div>

    </div>

</template>

<script>

import { createCompanyAlert, getCompaniesAlerts, updateCompanyAlert, deleteCompanyAlert } from '@/http/bff/company-bff-service';
import EmptyResult from '@/component/common/EmptyResult';
import { DEFAULT_LOADING_SVG } from '@/constants/AppConstants';
import CompanyType from '@/constants/CompanyType';
import CompanyAlertType from '@/constants/CompanyAlertType';
import { getFormattedValue } from '@/util/MoneyUtils';
import ConfirmDialog from "@/component/common/ConfirmDialog";
import UpdateCompanyAlertDialog from "./dialog/UpdateCompanyAlertDialog";
import CreateCompanyAlertDialog from "./dialog/CreateCompanyAlertDialog";
import { ElMessage } from 'element-plus';
import { BLANK_PAGE_ARG } from '@/constants/AppConstants';
import { COMPANY_DETAILS_PATH_WITHOUT_PARAM } from '@/constants/RoutesConstants';

export default {
    name: "company-alert-section",
    components: { EmptyResult, ConfirmDialog, CreateCompanyAlertDialog, UpdateCompanyAlertDialog },
    data() {
        return {
            companiesAlerts: {
                alerts: []
            },
            tableLoading: false,
            loadingSvg: DEFAULT_LOADING_SVG,
            companyAlertToCreate: {
                id: null,
                company: {
                    id: null
                },
                alertPrice: null,
                sendEmail: true,
                lastTimeSent: null,
                type: null
            },
            companyAlertToUpdate: {
                id: null,
                company: {
                    id: null
                },
                alertPrice: null,
                sendEmail: true,
                lastTimeSent: null,
                type: null
            },
            companyAlertIdToDelete: null,
            isCreateCompanyAlertDialogVisible: false,
            isUpdateCompanyAlertDialogVisible: false,
            isDeleteCompanyAlertDialogVisible: false
        }
    },
    created() {
        this.getCompaniesAlerts();
    },
    mounted() {
        document.title = "Ativos - Personal Invest";
    },
    methods: {
        getValueClassName(alert) {

            if(alert.type === 'LOWER_THAN_OR_EQUAL') {
                return alert.alertPrice >= alert.currentPrice ? "under-limit" : "above-limit";
            }

            if(alert.type === 'LOWER_THAN') {
                return alert.alertPrice > alert.currentPrice ? "under-limit" : "above-limit";
            }

            if(alert.type === 'HIGHER_THAN') {
                return alert.alertPrice < alert.currentPrice ? "under-limit" : "above-limit";
            }

            if(alert.type === 'HIGHER_THAN_OR_EQUAL') {
                return alert.alertPrice <= alert.currentPrice ? "under-limit" : "above-limit";
            }

            return "";

        },
        createCompanyAlert() {

            createCompanyAlert(this.companyAlertToCreate).then(() => {

                ElMessage({
                    message: 'Alerta criado com sucesso!',
                    type: 'success'
                });

                this.isCreateCompanyAlertDialogVisible = false;
                this.companyAlertToCreate = {
                    id: null,
                    company: {
                        id: null
                    },
                    alertPrice: null,
                    sendEmail: true,
                    lastTimeSent: null,
                    type: null
                };
                this.getCompaniesAlerts();

            }).catch(() => {
                ElMessage({
                    message: 'O alerta não pode ser criado, tente novamente mais tarde!',
                    type: 'error'
                });
            });

        },
        setCompanyAlert(newAlert) {
            this.companyAlertToCreate = newAlert;
        },
        updateCompanyAlert() {

            updateCompanyAlert(this.companyAlertToUpdate.id, this.companyAlertToUpdate).then(() => {

                ElMessage({
                    message: 'Alerta atualizado com sucesso!',
                    type: 'success'
                });

                this.isUpdateCompanyAlertDialogVisible = false;
                this.companyAlertToUpdate = {
                    id: null,
                    company: {
                        id: null
                    },
                    alertPrice: null,
                    sendEmail: true,
                    lastTimeSent: null,
                    type: null
                };
                this.getCompaniesAlerts();

            }).catch(() => {
                ElMessage({
                    message: 'O alerta não pode ser atualizado, tente novamente mais tarde!',
                    type: 'error'
                });
            });

        },
        deleteCompanyAlert() {

            deleteCompanyAlert(this.companyAlertIdToDelete).then(() => {

                ElMessage({
                    message: 'Alerta removido com sucesso!',
                    type: 'success'
                });

                this.isDeleteCompanyAlertDialogVisible = false;
                this.companyAlertIdToDelete = null;
                this.getCompaniesAlerts();

            }).catch(() => {
                ElMessage({
                    message: 'O alerta não pode ser removido, tente novamente mais tarde!',
                    type: 'error'
                });
            });

        },
        getCompaniesAlerts() {
            getCompaniesAlerts().then(response => {
                this.companiesAlerts = response.data;
            });
        },
        getCompanyType(type) {
            return CompanyType[type].description;
        },
        getType(type) {
            return CompanyAlertType[type].description;
        },
        getFormattedAlertPrice(alert) {

            if(alert !== null && alert !== undefined && alert.alertPrice !== null && alert.company.type !== null && alert.company.type !== undefined) {
                const locale = CompanyType[alert.company.type].locale;
                return getFormattedValue(alert.alertPrice, locale);
            }

            return "-";

        },
        getFormattedCurrentPrice(alert) {

            if(alert != null && alert != undefined && alert.company.type != null && alert.company.type != undefined) {
                const locale = CompanyType[alert.company.type].locale;
                return getFormattedValue(alert.currentPrice, locale);
            }

            return "-";

        },
        getLastAlertSentDate(lastTimeSent) {

            if(lastTimeSent !== null) {

                const date = new Date(lastTimeSent);
                const yyyy = date.getFullYear();
                let mm = date.getMonth() + 1;
                let dd = date.getDate();
                const hour = date.getHours();
                const minutes = date.getMinutes();

                if (dd < 10) dd = '0' + dd;
                if (mm < 10) mm = '0' + mm;

                return dd + '/' + mm + '/' + yyyy + " " + hour + ":" + minutes;

            }
            
            return 'Ainda não enviado';

        },
        getCompanyCurrency(company) {

            if(company.type == CompanyType.STOCK.value) {
            return { currency: "USD", };
            }

            return { currency: "BRL" };

        },
        showCreateCompanyAlertDialog() {
            this.isCreateCompanyAlertDialogVisible = true;
        },
        showUpdateCompanyAlertDialog(alert) {
            this.isUpdateCompanyAlertDialogVisible = true;
            this.companyAlertToUpdate = alert;
        },
        showDeleteCompanyAlertDialog(alertId) {
            this.isDeleteCompanyAlertDialogVisible = true;
            this.companyAlertIdToDelete = alertId;
        },
        closeCreateCompanyAlertDialog() {
            this.isCreateCompanyAlertDialogVisible = false;
            this.companyAlertToCreate = {
                id: null,
                company: {
                    id: null
                },
                alertPrice: null,
                sendEmail: true,
                lastTimeSent: null,
                type: null
            };
        },
        closeUpdateCompanyAlertDialog() {
            this.isUpdateCompanyAlertDialogVisible = false;
            this.companyAlertToUpdate = {
                id: null,
                company: {
                    id: null
                },
                alertPrice: null,
                sendEmail: true,
                lastTimeSent: null,
                type: null
            };
        },
        closeDeleteCompanyAlertDialog() {
            this.isDeleteCompanyAlertDialogVisible = false;
            this.companyAlertIdToDelete = null;
        },
        goToCompanyDetails(name) {
            const formattedUrl = COMPANY_DETAILS_PATH_WITHOUT_PARAM + "/" + name.toLowerCase();
            window.open(formattedUrl, BLANK_PAGE_ARG).focus();
        },
        getNotificationDescription(active) {
            return active ? "ATIVADA" : "DESATIVADA";
        },
        getEnabledAlerts(alert) {

            const descriptions = [];
            if(alert.sendEmail === true) {
                descriptions.push("Email");
            }

            if(alert.sendWebPush === true) {
                descriptions.push("Web push");
            }

            if(alert.sendWppPush === true) {
                descriptions.push("Whatsapp push");
            }

            return descriptions.length > 0 ? descriptions.join(", ") : "-";

        }
    },
    computed: {
        alerts() {
            this.companiesAlerts.alerts.forEach(alert => {
                alert.typeClassName = CompanyType[alert.company.type].className;
                alert.tagTypeClassName = CompanyType[alert.company.type].tagTypeClassName;
            });
            return this.companiesAlerts.alerts;
        },
        isCreateAlertConfirmButtonDisabled() {
            return this.companyAlertToCreate === null ||
                    this.companyAlertToCreate.company === null ||
                    this.companyAlertToCreate.company.id === null ||
                    this.companyAlertToCreate.alertPrice === null || 
                    this.companyAlertToCreate.alertPrice <= 0 ||
                    this.companyAlertToCreate.sendEmail === null &&
                    this.companyAlertToCreate.sendWebPush === null &&
                    this.companyAlertToCreate.lastTimeSent === null &&
                    this.companyAlertToCreate.type === null;
        },
        isUpdateAlertConfirmButtonDisabled() {
            return this.companyAlertToUpdate === null ||
                    this.companyAlertToUpdate.id === null ||
                    this.companyAlertToUpdate.company === null ||
                    this.companyAlertToUpdate.company.id === null ||
                    this.companyAlertToUpdate.alertPrice === null || 
                    this.companyAlertToUpdate.alertPrice <= 0 ||
                    this.companyAlertToUpdate.sendEmail === null &&
                    this.companyAlertToCreate.sendWebPush === null &&
                    this.companyAlertToUpdate.lastTimeSent === null &&
                    this.companyAlertToUpdate.type === null;
        }
    }
}

</script>

<style lang="scss" scoped>
    
    .company-alert-section {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .alerts-table {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .table-buttons {
        display: flex;
        flex-direction: row;
        margin-bottom: 5px;
        justify-content: flex-end;
    }

    .image-name {
        cursor: pointer;
    }

    .table-tag {
        width: 90%;
    }

    .above-limit {
      color: #ff484e !important;
    }

    .above-limit span {
      color: #ff484e !important;
    }

    .under-limit {
      color: #008000 !important;
      font-weight: bold;
    }

    .under-limit span {
      color: #008000 !important;
    }

    .current-price {
        color: #f2e33a !important;
    }

    @media screen and (max-width: 1024px) {

        .alerts-table {
            overflow: auto;
        }

    }

    @media screen and (min-width: 1024px) and (max-width: 1920px) {

        

    }

</style>
