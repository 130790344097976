<template>

  <div class="dividend-summary-section">

    <el-tabs v-model="activeName" @tab-click="handleChange">

      <el-tab-pane name="1">

        <template #label>
            <span :class="{ 'is-mouse-over': isMouseOverOne, 'is-active': activeName === '1' }" @mouseover="isMouseOverOne = true" @mouseout="isMouseOverOne = false">
              Proventos
            </span>
          </template>

        <section-row :isFirstSection="true">

          <h3>
            EVOLUÇÃO
          </h3>

          <div v-if="companiesDividendsList.length === 0">
            <EmptyResult></EmptyResult>
          </div>

          <div class="filters">

            <el-collapse v-model="activeBarCollapseNames">

              <el-collapse-item :name="1">

                <template #title>
                  <span class="filter-class collapse-border"> Filtros </span>
                </template>

                <div class="filter">

                  <span> Categoria: </span>
                  <div>
                    <el-select v-if="typeBarList.length > 0" v-model="selectedBarType" @change="changeTypesBarFilter()" class="m-2 category-select" placeholder="Selecione" size="small">
                      <el-option v-for="companyType in typeBarList" :key="companyType" :label="getType(companyType)" :value="companyType"/>
                    </el-select>
                    <el-tag v-for="st in selectedBarTypes" :key="st" class="mx-1 tag" closable :disable-transitions="false" @close="removeTypeBarFromList(st)">
                        {{ getType(st) }}
                    </el-tag>
                  </div>

                </div>

                <div class="filter">

                  <span> Ativo: </span>
                  <div>
                    <el-select v-if="companiesList.length > 0" v-model="selectedCompanyId" @change="changeCompaniesFilter()" class="m-2 category-select" placeholder="Selecione" size="small">
                      <el-option v-for="company in companiesList" :key="company.id" :label="company.name" :value="company.id"/>
                    </el-select>
                    <el-tag v-for="sC in selectedCompaniesIds" :key="sC" class="mx-1 tag" closable :disable-transitions="false" @close="removeCompaniesFromList(sC)">
                        {{ getCompany(sC) }}
                    </el-tag>
                  </div>

                </div>

              </el-collapse-item>

            </el-collapse>

          </div>

          <div v-if="companiesDividendsList.length > 0" class="statistics">

            <div  class="statistic-item">
                <span> <b> Geral </b> </span>
                <el-tag :key="companiesDividends.total" class="mx-1 tag total-tag" :disable-transitions="false">
                    <span> {{ getTotal(companiesDividends.total) }} </span>
                </el-tag>
            </div>

            <div class="types-statistics">

              <div v-for="tbt in typesStatisticsItems" :key="tbt.type" class="statistic-item">
                <span v-if="companiesDividends.totalByType.length > 0" :class="getClassByType(tbt.type)"> <b> {{ getType(tbt.type) }} </b> </span>
                <el-tag v-if="companiesDividends.totalByType.length > 0" :key="tbt.total" class="mx-1 tag total-tag" :disable-transitions="false">
                  <span> {{ getTotalByType(tbt.total, tbt.type) }} </span>
                </el-tag>
              </div>

            </div>

            <div class="companies-statistics">

              <div v-for="tbt in companiesStatisticsItems" :key="tbt.type" class="statistic-item">
                <span v-if="companiesDividends.totalByType.length > 0" :class="getClassByType(tbt.type)"> <b> {{ tbt.name }} </b> </span>
                <el-tag v-if="companiesDividends.totalByType.length > 0" :key="tbt.total" class="mx-1 tag total-tag" :disable-transitions="false">
                  <span> {{ getTotalByType(tbt.total, tbt.type) }} </span>
                </el-tag>
              </div>

            </div>

          </div>

          <div v-if="companiesDividendsList.length > 0" class="dividend-summary-bar">
            <Bar ref="bar" :data="getBarData" :options="barOptions" />
          </div>

          <h3>
            POR ATIVO
          </h3>

          <div class="filters">

            <el-collapse v-model="activeNames">

              <el-collapse-item :name="1">

                <template #title>
                  <span class="filter-class collapse-border"> Filtros </span>
                </template>

                <div class="filter">

                  <span> Mês: </span>
                  <div>
                    <el-select v-model="selectedMonth" class="month-select" @change="refreshDoughnutData()" placeholder="Selecione um mês">
                      <el-option
                        v-for="month in allowedMonths"
                        :key="month.label"
                        :label="month.label"
                        :value="month.label">
                      </el-option>
                    </el-select>
                  </div>

                </div>

                <div class="filter">

                  <span> Categoria: </span>
                  <div>
                    <el-select v-if="typeList.length > 0" v-model="selectedType" @change="changeTypeFilter()" class="m-2 category-select" placeholder="Selecione" size="small">
                      <el-option v-for="companyType in typeList" :key="companyType" :label="getType(companyType)" :value="companyType"/>
                    </el-select>
                    <el-tag v-for="st in selectedTypes" :key="st" class="mx-1 tag" closable :disable-transitions="false" @close="removeTypeFromList(st)">
                        {{ getType(st) }}
                    </el-tag>
                  </div>

                </div>

              </el-collapse-item>

            </el-collapse>

          </div>

          <div class="dividend-summary-table-doughnut">

            <div class="table-info">

              <table v-loading="tableLoading" :element-loading-svg="loadingSvg" element-loading-text="Carregando..." element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="transparent" class="config-table">

                <thead>
                    <tr>
                        <th> Categoria </th>
                        <th> Nome </th>
                        <th> Data de pagamento </th>
                        <th> Quantidade </th>
                        <th> Multplicador </th>
                        <th> Total </th>
                    </tr>
                </thead>
                <tbody v-if="companiesDividendsList.length > 0">
                    <tr v-for="companyConfig in companiesDividendsList" :key="companyConfig">

                        <td>
                          <span :class="companyConfig.typeClassName"> {{ getType(companyConfig.type) }} </span>
                        </td>

                        <td @click="goToCompanyDetails(companyConfig)">

                          <div class="image-name">
                            <img v-if="companyConfig.imageUrl != null" style="margin-right: 10px" :src="companyConfig.imageUrl" width="30" height="20">
                            <span :class="companyConfig.tagTypeClassName"> {{ companyConfig.name }} </span>
                          </div>
                          
                        </td>

                        <td>
                          <span> {{ getDateTime(companyConfig.paymentDate) }} </span>
                        </td>

                        <td>
                          <span> {{ companyConfig.amount.toString().replace(".", ",") }} </span>
                        </td>

                        <td>
                          <span> {{ companyConfig.rate.toString().replace(".", ",") }} </span>
                        </td>

                        <td>
                          <span> {{ getFormattedTotalValue(companyConfig) }} </span>
                          <el-tooltip v-if="companyConfig.type == CompanyType.STOCK.value" class="box-item" effect="dark" :content="getDollarValueFormatted()" placement="top">
                            <el-icon><Info/></el-icon>
                          </el-tooltip>
                        </td>

                    </tr>

                    <tr>
                        <td class="total-row"> GERAL </td>
                        <td class="total-row"> - </td>
                        <td class="total-row"> - </td>
                        <td class="total-row"> - </td>
                        <td class="total-row"> - </td>
                        <td class="total-row"> {{ getPtFormattedPrice(companiesDividendsListTotal) }} </td>
                    </tr>
                    
                </tbody>

              </table>
              <div v-if="companiesDividendsList.length === 0">
                <EmptyResult></EmptyResult>
              </div>

            </div>

            <div v-if="companiesDividendsList.length > 0" class="doughnut">
              <Doughnut :data="getData" :options="options" />
            </div>

          </div>

        </section-row>

      </el-tab-pane>

      <el-tab-pane name="2">

        <template #label>
          <span :class="{ 'is-mouse-over': isMouseOverTwo, 'is-active': activeName === '2' }" @mouseover="isMouseOverTwo = true" @mouseout="isMouseOverTwo = false">
            Magic number
          </span>
        </template>

        <section-row :isFirstSection="true">

          <h3> MAGIC NUMBER </h3>
          <div class="filters">

            <el-collapse v-model="magicNumberActiveNames">

              <el-collapse-item :name="1">

                <template #title>
                  <span class="filter-class collapse-border"> Filtros </span>
                </template>

                <div class="filter">

                  <div>
                    <el-select v-model="magicNumberSelectedMonth" class="month-select" @change="refreshDoughnutData()" placeholder="Selecione um mês">
                      <el-option
                        v-for="month in allowedMonths"
                        :key="month.label"
                        :label="month.label"
                        :value="month.label">
                      </el-option>
                    </el-select>
                  </div>

                </div>

              </el-collapse-item>

            </el-collapse>

          </div>

          <div class="table-info">

            <table v-loading="tableLoading" :element-loading-svg="loadingSvg" element-loading-text="Carregando..." element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="transparent" class="config-table">
              
              <thead>
                <tr>
                  <th> Categoria </th>
                  <th> Nome </th>
                  <th> Quantidade </th>
                  <th> Multiplicador </th>
                  <th> Total </th>
                  <th> Atingido </th>
                  <th> Próximo </th>
                  <th> Preço </th>
                  <th> Próximo (R$/US$) </th>
                </tr>
              </thead>
              <tbody v-if="currentCompaniesDividendsList.length > 0">
                <tr v-for="companyConfig in currentCompaniesDividendsList" :key="companyConfig">

                  <td>
                    <span :class="companyConfig.typeClassName"> {{ getType(companyConfig.type) }} </span>
                  </td>

                  <td @click="goToCompanyDetails(companyConfig)">

                    <div class="image-name">
                      <img v-if="companyConfig.imageUrl != null" style="margin-right: 10px" :src="companyConfig.imageUrl" width="30" height="20">
                      <span :class="companyConfig.tagTypeClassName"> {{ companyConfig.name }} </span>
                    </div>

                  </td>
                  
                  <td>
                    <span> {{ companyConfig.amount.toString().replace(".", ",") }} </span>
                  </td>

                  <td>
                    <span> {{ companyConfig.rate.toString().replace(".", ",") }} </span>
                    <el-tooltip v-if="companyConfig.previousMagicNumber" class="box-item" effect="dark" :content="getMagicNumberPaymentDateContent(null, companyConfig)" placement="top">
                      <el-icon class="info-icon"><InfoFilled/></el-icon>
                    </el-tooltip>
                  </td>

                  <td>
                    <span> {{ formatValue(companyConfig.total, companyConfig.type) }} </span>
                    <el-tooltip v-if="companyConfig.previousMagicNumber" class="box-item" effect="dark" :content="getMagicNumberPaymentDateContent('Total', companyConfig)" placement="top">
                      <el-icon class="info-icon"><InfoFilled/></el-icon>
                    </el-tooltip>
                  </td>

                  <td>
                    <span :class="isMagicNumberReached(companyConfig.reachedMagicNumbers)"> {{ companyConfig.reachedMagicNumbers.toString().replace(".", ",") }} </span>
                    <el-tooltip v-if="companyConfig.previousMagicNumber" class="box-item" effect="dark" :content="getMagicNumberPaymentDateContent('Atingido', companyConfig)" placement="top">
                      <el-icon class="info-icon"><InfoFilled/></el-icon>
                    </el-tooltip>
                  </td>

                  <td>
                    <span> {{ companyConfig.amountToNextMagicNumber.toString().replace(".", ",") }} </span>
                    <el-tooltip v-if="companyConfig.previousMagicNumber" class="box-item" effect="dark" :content="getMagicNumberPaymentDateContent('Próximo Magic Number', companyConfig)" placement="top">
                      <el-icon class="info-icon"><InfoFilled/></el-icon>
                    </el-tooltip>
                  </td>

                  <td>
                    <span> {{ formatValue(companyConfig.price, companyConfig.type) }} </span>
                  </td>

                  <td>
                    <span> {{ formatValue(companyConfig.moneyToNextMagicNumberNeeded, companyConfig.type) }} </span>
                    <el-tooltip v-if="companyConfig.previousMagicNumber" class="box-item" effect="dark" :content="getMagicNumberPaymentDateContent('Próximo Magic Number', companyConfig)" placement="top">
                      <el-icon class="info-icon"><InfoFilled/></el-icon>
                    </el-tooltip>
                  </td>

                </tr>
              </tbody>
            
            </table>
            <div v-if="currentCompaniesDividendsList.length === 0">
              <EmptyResult></EmptyResult>
            </div>

          </div>
          <div class="table-info" v-if="currentCompaniesDividendsList.length > 0">
            <table-information :bgcolor="'green'" :bcolor="'green'" :text="'Pelo menos um magic number atigindo'"/>
            <table-information :bgcolor="'yellow'" :bcolor="'yellow'" :text="'Nenhum magic number atigindo'"/>
          </div>

        </section-row>

      </el-tab-pane>

    </el-tabs>

    <div class="back-button">
      <el-tooltip class="box-item" effect="dark" content="Voltar para a última aba" placement="top">
        <button @click="goBack()" > Voltar </button>
      </el-tooltip>
    </div>
  
  </div>

</template>

<script>

  import { Chart as ChartJS, Title, BarElement, CategoryScale, LinearScale, ArcElement, Tooltip, Legend, Colors } from 'chart.js'
  import { Doughnut, Bar } from 'vue-chartjs'
  import { getCompaniesDividends } from '../../http/bff/earning-bff-service';
  import { ElMessage } from 'element-plus'
  import { getFormattedValue } from '../../util/MoneyUtils';
  import { DEFAULT_LOADING_SVG, BLANK_PAGE_ARG } from '../../constants/AppConstants';
  import { COMPANY_DETAILS_PATH_WITHOUT_PARAM } from '@/constants/RoutesConstants';
  import CompanyType from '../../constants/CompanyType';
  import TableInformation from '@/component/common/TableInformation';
  import SectionRow from '@/component/common/SectionRow';
  import EmptyResult from '@/component/common/EmptyResult';

  ChartJS.register(ArcElement, Tooltip, Legend, Colors, Title, BarElement, CategoryScale, LinearScale)

  export default {
    name: 'dividend-summary-section',
    components: { SectionRow, Doughnut, Bar, TableInformation, EmptyResult },
    data() {
      return {
        loadingSvg: DEFAULT_LOADING_SVG,
        companiesDividends: {
          companies: [],
          currentCompaniesMagicNumber: [],
          total: 0,
          totalByType: []
        },
        CompanyType,
        barData: {
          labels: [],
          datasets: [
            {
              label: 'Geral',
              backgroundColor: '#409eff',
              data: []
            }
          ]
        },
        barOptions: {
          responsive: true,
          maintainAspectRatio: false
        },
        data: {
          datasets: [
            {
              labels: [],
              backgroundColor: [],
              data: []
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false
        },
        selectedMonth: this.getCurrentMonthLabel().label,
        magicNumberSelectedMonth: this.getCurrentMonthLabel().label,
        selectedType: null,
        selectedTypes: [ "FII" ],
        activeNames: 1,
        activeName: '1',
        isMouseOverOne: false,
        isMouseOverTwo: false,
        lastActiveName: '1',
        magicNumberActiveNames: 1,
        selectedCompanyId: null,
        selectedCompaniesIds: [],
        companies: [],
        allCompanies: [],
        selectedBarType: null,
        selectedBarTypes: [ "FII" ],
        activeBarCollapseNames: 1
      }
    },
    mounted() {
      document.title = "Proventos - Personal Invest";
    },
    created() {
      this.getCompaniesDividends();
    },
    methods: {
      handleChange() {
        this.lastActiveName = this.activeName;
        window.scroll(0, 0);
      },
      goBack() {

        const lastActiveName = this.activeName;

        this.activeName = this.lastActiveName;
        this.lastActiveName = lastActiveName;
        window.scroll(0, 0);

      },
      changeTypeFilter() {
        this.selectedTypes.push(this.selectedType);
        this.selectedType = null;
        this.refreshDoughnutData();
      },
      changeCompaniesFilter() {
        this.selectedCompaniesIds.push(this.selectedCompanyId);
        this.selectedCompanyId = null;
        this.getCompaniesDividends();
      },
      changeTypesBarFilter() {
        this.selectedBarTypes.push(this.selectedBarType);
        this.selectedBarType = null;
        this.refreshBarData();
      },
      removeTypeFromList(type) {
      
        const typeIndex = this.selectedTypes.findIndex(typeFromList => typeFromList === type);
        if(typeIndex >= 0) {
          this.selectedTypes.splice(typeIndex, 1);
          this.selectedType = null;
          this.refreshDoughnutData();
        }

      },
      removeCompaniesFromList(id) {
      
        const companyIndex = this.selectedCompaniesIds.findIndex(companiesFromList => companiesFromList === id);
        if(companyIndex >= 0) {
          this.selectedCompaniesIds.splice(companyIndex, 1);
          this.selectedCompanyId = null;
          this.getCompaniesDividends();
        }

      },
      removeTypeBarFromList(type) {
      
        const typeIndex = this.selectedBarTypes.findIndex(typeFromList => typeFromList === type);
        if(typeIndex >= 0) {
          this.selectedBarTypes.splice(typeIndex, 1);
          this.selectedBarType = null;
          this.refreshBarData();
        }

      },
      getCompany(id) {
        const company = this.allCompanies.find(company => company.id === id);
        return company.name;
      },
      isMagicNumberReached(value) {
        return value >= 1 ? "magic-number-reached" : "magic-number-not-reached";
      },
      refreshBarData() {

        this.barData = {
          labels: [],
          datasets: [
            {
              label: 'Geral',
              backgroundColor: '#409eff',
              data: []
            }
          ]
        }

        this.barData.labels = this.barLabels;
        this.barData.datasets[0].data = this.barDataValues;

        const existingTypes = this.companiesDividends.totalByType.filter(company => company.name === null).map(company => company.type);
        Object.keys(CompanyType).forEach(type => {

          if(existingTypes.includes(type) && this.selectedBarTypes.includes(type)) {

            const dataFromType = this.getBarDataByType(type);
            if(dataFromType.length > 0) {

              const newDataset = {
                label: CompanyType[type].description,
                backgroundColor: CompanyType[type].color,
                data: dataFromType
              };

              this.barData.datasets.push(newDataset);

            }
          
          }

        });

        const companies = this.companiesDividends.totalByType.filter(company => company.name !== null);
        companies.forEach(company => {

          const companyId = this.allCompanies.find(c => c.name === company.name).id;
          const dataFromCompany = this.getBarDataByCompany(companyId);
          if(dataFromCompany.length > 0) {

            const newDataset = {
              label: company.name,
              backgroundColor: CompanyType[company.type].color,
              data: dataFromCompany
            };

            this.barData.datasets.push(newDataset);

          }

        });

      },
      refreshDoughnutData() {
        this.data = {
          datasets: [
            {
              labels: [],
              backgroundColor: [],
              data: []
            }
          ]
        };
        this.data.labels = this.doughnutLabels;
        this.data.datasets[0].backgroundColor = this.doughnutBackgroundColors;
        this.data.datasets[0].data = this.doughnutData;
      },
      sortCompaniesByTypeAndNameAndPaymentDate() {
        this.companiesDividends.companies.sort((c1, c2) => {

          let typeComparison = 0;
          if(c1.type < c2.type) {
            typeComparison = -1;
          }

          if(c1.type > c2.type) {
            typeComparison = 1;
          }

          let paymentDateComparison = 0;
          const c1PaymentDateTimestamp = Date.parse(c1.paymentDate);
          const c2PaymentDateTimestamp = Date.parse(c2.paymentDate);
          if(c1PaymentDateTimestamp < c2PaymentDateTimestamp) {
            paymentDateComparison = -1;
          }

          if(c1PaymentDateTimestamp > c2PaymentDateTimestamp) {
            paymentDateComparison = 1;
          }
          
          return typeComparison || paymentDateComparison;

        });
      },
      getCompaniesDividends() {
        this.companiesDividends = {
          companies: [],
          currentCompaniesMagicNumber: [],
          total: 0,
          totalByType: []
        };
        getCompaniesDividends(this.selectedCompaniesIds).then(response => {
          this.companiesDividends = response.data;
          this.sortCompaniesByTypeAndNameAndPaymentDate();
          this.refreshBarData();
          this.refreshDoughnutData();
          this.allCompanies = this.companiesDividends.companies.filter((value, index, arr) => {
                                return index === arr.findIndex(arrItem => arrItem.id === value.id);
                              });
          this.companies = this.companiesDividends.companies.filter((value, index, arr) => {
                            return index === arr.findIndex(arrItem => arrItem.id === value.id);
                          }).filter(company => !this.selectedCompaniesIds.includes(company.id));
        }).catch(() => {
            ElMessage({
                message: 'Erro ao buscar os dividendos, tente novamente mais tarde!',
                type: 'error'
            });
        });
      },
      getType(type) {
        return CompanyType[type].description;
      },
      getTotal(value) {
        return getFormattedValue(value, "pt_br");
      },
      getTotalByType(value, type) {

        if(CompanyType.STOCK.value === type) {
          value = value * this.companiesDividends.dollarValue;
        }

        return getFormattedValue(value, "pt_br");

      },
      formatValue(value, type) {

        if(type !== null) {
          const locale = CompanyType[type].locale;
          return getFormattedValue(value, locale);
        }

        return "-";

      },
      getFormattedTotalValue(company) {

        if(company.type !== null) {
          const locale = CompanyType[company.type].locale;
          return getFormattedValue(company.total, locale);
        }

        return "-";

      },
      getDollarValueFormatted() {
        return "Cotação atual " + getFormattedValue(this.companiesDividends.dollarValue, "pt_br");
      },
      getDateTime(dateTime) {

        if(dateTime !== undefined && dateTime !== null) {
          var date = new Date(dateTime);
          return date.toLocaleDateString("pt-BR", { timeZone: 'UTC' });
        }

        return null;

      },
      getPtFormattedPrice(price) {
        return getFormattedValue(price, "pt_br");
      },
      getCurrentMonthLabel() {

        const yearMonths = [
          {
            name: "Janeiro",
            index: 0
          },
          {
            name: "Fevereiro",
            index: 1
          },
          {
            name: "Março",
            index: 2
          },
          {
            name: "Abril",
            index: 3
          },
          {
            name: "Maio",
            index: 4
          },
          {
            name: "Junho",
            index: 5
          },
          {
            name: "Julho",
            index: 6
          },
          {
            name: "Agosto",
            index: 7
          },
          {
            name: "Setembro",
            index: 8
          },
          {
            name: "Outubro",
            index: 9
          },
          {
            name: "Novembro",
            index: 10
          },
          {
            name: "Dezembro",
            index: 11
          }
        ];
        const now = new Date();
        
        return {
          label: yearMonths[now.getMonth()].name + "/" + now.getFullYear(),
          name: yearMonths[now.getMonth()].name,
          index: now.getMonth(),
          year: now.getFullYear()
        };

      },
      getClassByType(type) {
        return CompanyType[type].className;
      },
      getBarDataByType(type) {

        let barData = [];
        this.allowedMonths.forEach(allowedMonth => {

          const companiesDividendsOfMonth = this.companiesDividends.companies.filter(companyDividend => {

            const paymentDate = new Date(companyDividend.paymentDate);
            const isSameType = companyDividend.type === type;

            return isSameType && allowedMonth.index === paymentDate.getMonth() && allowedMonth.year === paymentDate.getFullYear();

          });

          const companiesOfMonthTypeTotal = companiesDividendsOfMonth.reduce((partialSum, companyDividend) => {

            if(companyDividend.type === CompanyType.STOCK.value) {
              return (companyDividend.total * this.companiesDividends.dollarValue) + partialSum;
            }

            return companyDividend.total + partialSum;

          }, 0);

          if(companiesOfMonthTypeTotal >= 0) {
            barData.push(companiesOfMonthTypeTotal);
          }

        });

        return barData;

      },
      getBarDataByCompany(companyId) {

        let barData = [];
        this.allowedMonths.forEach(allowedMonth => {

          const companiesDividendsOfMonth = this.companiesDividends.companies.filter(companyDividend => {

            const paymentDate = new Date(companyDividend.paymentDate);
            const isSameCompany = companyDividend.id === companyId;

            return isSameCompany && allowedMonth.index === paymentDate.getMonth() && allowedMonth.year === paymentDate.getFullYear();

          });

          const companiesOfMonthTypeTotal = companiesDividendsOfMonth.reduce((partialSum, companyDividend) => {

            if(companyDividend.type === CompanyType.STOCK.value) {
              return (companyDividend.total * this.companiesDividends.dollarValue) + partialSum;
            }

            return companyDividend.total + partialSum;

          }, 0);

          if(companiesOfMonthTypeTotal >= 0) {
            barData.push(companiesOfMonthTypeTotal);
          }

        });

        return barData;

      },
      formatDate(date) {

        const yyyy = date.getFullYear();
        let mm = date.getMonth() + 1;
        let dd = date.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return dd + '/' + mm + '/' + yyyy;

      },
      getMagicNumberPaymentDateContent(prefix, companyConfig) {
        const paymentDate = new Date(companyConfig.paymentDate);
        return prefix != null ? 
          prefix + " com multiplicador referente ao dia " + this.formatDate(paymentDate) : 
          "Multiplicador referente ao dia " + this.formatDate(paymentDate);
      },
      goToCompanyDetails(company) {
        const formattedUrl = COMPANY_DETAILS_PATH_WITHOUT_PARAM + "/" + company.name.toLowerCase();
        window.open(formattedUrl, BLANK_PAGE_ARG).focus();
      },
      monthDiff(d1, d2) {
        var months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
      }
    },
    computed: {
      companiesDividendsList() {

        const companies = this.companiesDividends.companies.filter(company => {

          const paymentDate = new Date(company.paymentDate);
          const allowedMonth = this.allowedMonths.find(am => am.label === this.selectedMonth);

          return (this.selectedTypes.length === 0 || this.selectedTypes.includes(company.type)) && 
          allowedMonth.index === paymentDate.getMonth() && 
          allowedMonth.year === paymentDate.getFullYear();

        });
        companies.forEach(company => {
          company.typeClassName = CompanyType[company.type].className;
          company.tagTypeClassName = CompanyType[company.type].tagTypeClassName;
        });

        return companies;

      },
      currentCompaniesDividendsList() {

        let companies = [];
        const allowedMonth = this.allowedMonths.find(am => am.label === this.magicNumberSelectedMonth);
        const now = new Date();
        if(allowedMonth.index === now.getMonth() && allowedMonth.year === now.getFullYear()) {
          companies = this.companiesDividends.currentCompaniesMagicNumber;
        } else {

          companies = this.companiesDividends.companies.filter(company => {

            if(company.type === CompanyType.FII.value) {
              const paymentDate = new Date(company.paymentDate);
              return allowedMonth.index === paymentDate.getMonth() && allowedMonth.year === paymentDate.getFullYear();
            }

            return false;

          });

        }

        companies.forEach(company => {

          company.typeClassName = CompanyType[company.type].className;
          company.tagTypeClassName = CompanyType[company.type].tagTypeClassName;
          const paymentDate = new Date(company.paymentDate);
          if(allowedMonth.index !== paymentDate.getMonth() || allowedMonth.year !== paymentDate.getFullYear()) {
            company.previousMagicNumber = true;
          }

        });

        companies.sort((c1, c2) => {
          let nameComparison = 0;
          if(c1.name < c2.name) {
            nameComparison = -1;
          }

          if(c1.name > c2.name) {
            nameComparison = 1;
          }

          return nameComparison;

        });

        return companies;

      },
      companiesDividendsListTypes() {
        
        let allTypes = [
          {
            value: "ALL",
            label: "Todos"
          }
        ];

        const companies = this.companiesDividends.companies.filter(company => {

          const paymentDate = new Date(company.paymentDate);
          const allowedMonth = this.allowedMonths.find(am => am.label === this.selectedMonth);

          return allowedMonth.index === paymentDate.getMonth() && allowedMonth.year === paymentDate.getFullYear();

        });

        const defaultTypes = companies.map(company => company.type);
        defaultTypes.forEach(defaultType => {

          const alreadyAddedType = allTypes.find(t => t.value === defaultType); 
          if(!alreadyAddedType) {

            const type = {
              value: defaultType,
              label: CompanyType[defaultType].description
            };

            allTypes.push(type);

          }

        });

        return allTypes;

      },
      companiesDividendsListTotal() {
        return this.companiesDividendsList.reduce((partialSum, companyDividend) => {

          if(CompanyType.STOCK.value === companyDividend.type) {
            return (companyDividend.total * this.companiesDividends.dollarValue) + partialSum;
          }

          return companyDividend.total + partialSum;

        }, 0);
      },
      getBarData() {
        return this.barData;
      },
      getData() {
          return this.data;
      },
      companiesDividendsListByType() {

        const companiesByTypeOfSelectedMonth = [];
        const companiesTypes = Object.keys(CompanyType);
        companiesTypes.forEach(companyType => {

          const companiesOfTypeTotal = this.companiesDividendsList.reduce((partialSum, companyDividend) => {
            if(companyType == companyDividend.type) {
              return companyDividend.total + partialSum;
            }
            return 0 + partialSum;
          }, 0);

          if(companiesOfTypeTotal > 0) {

            const companyTypeInfo = {
              type: companyType,
              total: companiesOfTypeTotal
            };
            companiesByTypeOfSelectedMonth.push(companyTypeInfo);

          }

        });

        return companiesByTypeOfSelectedMonth;

      },
      barLabels() {
        return this.allowedMonths.map(allowedMonth => allowedMonth.label);
      },
      barDataValues() {

        let barData = [];
        this.allowedMonths.forEach(allowedMonth => {

          const companiesDividendsOfMonth = this.companiesDividends.companies.filter(companyDividend => {
            const paymentDate = new Date(companyDividend.paymentDate);
            return allowedMonth.index === paymentDate.getMonth() && allowedMonth.year === paymentDate.getFullYear();
          });

          const companiesOfMonthTotal = companiesDividendsOfMonth.reduce((partialSum, companyDividend) => {

            if(companyDividend.type === CompanyType.STOCK.value) {
              return (companyDividend.total * this.companiesDividends.dollarValue) + partialSum;
            }

            return companyDividend.total + partialSum;

          }, 0);

          barData.push(companiesOfMonthTotal);

        });

        return barData;

      },
      doughnutLabels() {
          return this.companiesDividendsListByType.map(tbt => CompanyType[tbt.type].description);
      },
      doughnutBackgroundColors() {
          return this.companiesDividendsListByType.map(tbt => CompanyType[tbt.type].color);
      },
      doughnutData() {
          return this.companiesDividendsListByType.map(tbt => {

              if(tbt.type === CompanyType.STOCK.value && this.companiesDividends.dollarValue > 0) {
                  return tbt.total * this.companiesDividends.dollarValue;
              }

              return tbt.total;

          });
      },
      allowedMonths() {

        const yearMonths = [
          {
            name: "Janeiro",
            index: 0
          },
          {
            name: "Fevereiro",
            index: 1
          },
          {
            name: "Março",
            index: 2
          },
          {
            name: "Abril",
            index: 3
          },
          {
            name: "Maio",
            index: 4
          },
          {
            name: "Junho",
            index: 5
          },
          {
            name: "Julho",
            index: 6
          },
          {
            name: "Agosto",
            index: 7
          },
          {
            name: "Setembro",
            index: 8
          },
          {
            name: "Outubro",
            index: 9
          },
          {
            name: "Novembro",
            index: 10
          },
          {
            name: "Dezembro",
            index: 11
          }
        ];

        let firstDate = new Date();

        if(this.companiesDividends.firstDividendDate) {
          firstDate = new Date(this.companiesDividends.firstDividendDate);
        }
        const allowedMonths = [];
        const monthDiff = this.monthDiff(firstDate, new Date());
        for (let index = 0; index <= monthDiff; index++) {
          
          const monthToPush = {};
          Object.assign(monthToPush, yearMonths.find(yearMonth => yearMonth.index === firstDate.getMonth()));
          
          const year = firstDate.getFullYear();
          monthToPush.label = monthToPush.name + "/" + year;
          monthToPush.year = year;

          allowedMonths.push(monthToPush);

          firstDate.setMonth(firstDate.getMonth() + 1);
          
        }

        return allowedMonths;

      },
      typeList() {
        let typeList = Object.keys(CompanyType);
        return typeList.filter(type => !this.selectedTypes.includes(type));
      },
      typeBarList() {
        let typeList = Object.keys(CompanyType);
        return typeList.filter(type => !this.selectedBarTypes.includes(type));
      },
      companiesList() {
        return this.companies;
      },
      typesStatisticsItems() {
        return this.companiesDividends.totalByType.filter(tbt => tbt.name === null && tbt.type !== null);
      },
      companiesStatisticsItems() {
        return this.companiesDividends.totalByType.filter(tbt => tbt.name !== null);
      }
    }
  }

</script>

<style lang="scss" scoped>

  @media screen and (max-width: 1024px) {

    .table-tag {
      width: 80%;
    }

    .statistic-item {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 5px;
    }

    .table-info {
      width: 100%;
      overflow: auto;
    }

    .dividend-summary-table-doughnut {
      text-align: center;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      align-items: center;
      width: 100%;
    }

    .doughnut {
      margin-top: 20px;
    }

    .tag {
      margin-top: 5px;
      width: 70px;
    }

    .month-select {
      width: 60%;
    }

    .category-select {
      width: 35%;
    }

    .types-statistics {
      overflow: auto;
      width: 100%;
    }

    .companies-statistics {
      overflow: auto;
      width: 100%;
    }

  }

  @media screen and (min-width: 1024px) and (max-width: 1920px) {

    .table-tag {
      width:  90%;
    }

    .statistic-item {
      width: 33%;
      height: inherit;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    
    .table-info {
      width: 100%;
    }

    .dividend-summary-table-doughnut {
      text-align: center;
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      align-items: center;
    }

    .tag {
      margin-top: 5px;
      width: 120px;
    }

  }

  .dividend-summary-section {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    align-items: flex-start;
    width: 100%;
  }

  .statistics {
    display: flex;
    margin-top: 20px;
    margin-bottom: 50px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .dividend-summary-bar {
    text-align: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    align-items: flex-start;
    width: 100%;
    height: 400px;
  }

  .filter {
    margin: 10px;
    margin-left: 0px;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .doughnut {
    width: 70%;
  }

  .month-filter {
    float: left;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .types-statistics {
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: space-between;
  }

  .companies-statistics {
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: space-between;
  }

  .total-tag {
    box-shadow: inset 0px 0px 7px 3px $default-primary-tag-bg-color;
    background-color: $default-primary-tag-bg-color !important;
    color: "#03a94e" !important;
  }

  .filters {
    width: 100%;
  }

  .magic-number-reached {
    color: green;
  }

  .magic-number-not-reached {
    color: yellow;
  }

  .image-name {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .el-tabs {
    width: 100%;
  }

  .back-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .info-icon {
    color: $default-primary-title-text-color;
  }

  .statistics-filter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin: 10px;
  }

</style>